.zd-input {
    width: 100%;
    position: relative;
    display: inline-block;
}

.zd-input__inner {
    -webkit-appearance: none;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    line-height: 30px;
    outline: none;
    padding: 0 15px;
    transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
    width: 100%;
}

.zd-input--suffix .zd-input__inner {
    padding-right: 30px;
}

.zd-input__suffix {
    position: absolute;
    height: 100%;
    right: 5px;
    top: 0;
    text-align: center;
    color: #c0c4cc;
    transition: all .3s;
    pointer-events: none;
}

.zd-input__suffix-inner {
    pointer-events: all;

}

.zd-cascader-wrap .zd-input__inner {
    text-overflow: ellipsis;
}

.zd-input__icon {
    height: 100%;
    text-align: center;
    width: 25px;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: baseline;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.zd-input__icon.zd-icon-arrow-down {
    transition: transform .3s;
    width: 30px;
}

.zd-input__icon.zd-icon-arrow-right {
    transition: transform .3s;
    font-size: 13px;
    transform: rotate(270deg);
}

.zd-input__icon.zd-icon-check {
    width: 13px;
}

.zd-cascader-wrap {
    width: 100%;
    height: 30px;
    line-height: 30px;
    position: relative;
    display: inline-block;
    font-size: 13px;
}

.zd-cascader__dropdown {
    position: absolute;
    top: 40px;
    left: 0;
    z-index: 2009;
    margin: 5px 0 12px;
    font-size: 13px;
    background: #fff;
    border: 1px solid #e4e7ed;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    display: none;
}

.zd-cascader-panel {
    display: flex;
    border-radius: 4px;
    font-size: 13px;
}

.zd-scrollbar {
    overflow: hidden;
    position: relative;
}

.zd-scrollbar__wrap {
    overflow: scroll;
    height: 100%;

}

.zd-cascader-menu {
    min-width: 160px;
    box-sizing: border-box;
    color: #606266;
    border-right: 1px solid #e4e7ed;
}

.zd-cascader-menu:last-child {

    border-right: none;

}

.zd-cascader-menu__wrap {
    margin-bottom: -17px;
    margin-right: -17px;
    height: 204px;

}

.zd-cascader-menu__list {
    position: relative;
    min-height: 100%;
    margin: 0;
    padding: 6px 0;
    list-style: none;
    box-sizing: border-box;
}

.zd-cascader-node {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 30px 0 20px;
    height: 34px;
    line-height: 34px;
    outline: none;
}

.zd-cascader-node:not(.is-disabled) {
    cursor: pointer;
}

.zd-cascader-node__label {
    flex: 1;
    padding: 0 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.zd-cascader-node__postfix {
    position: absolute;
    right: 10px;
}

.zd-cascader-node:not(.is-disabled):focus,
.zd-cascader-node:not(.is-disabled):hover,
.zd-cascader-node:not(.is-disabled).is-prepare {
    background: #f5f7fa;
}

.zd-cascader-node.in-active-path,
.zd-cascader-node.is-active,
.zd-cascader-node.is-selectable.in-checked-path {
    color: #409eff;
    font-weight: 700;
}

.zd-cascader-node.in-active-path .zd-input__icon path,
.zd-cascader-node.is-active .zd-input__icon path,
.zd-cascader-node.is-selectable.in-checked-path .zd-input__icon path {
    fill: #409eff;
}

.zd-cascader-wrap:not(.is-disabled):hover .zd-input__inner {
    cursor: pointer;
    border-color: #c0c4cc;
}

.zd-cascader-wrap.is-focus .zd-input .zd-input__inner {
    border-color: #409eff;
}

.zd-cascader-wrap.is-focus .zd-input .zd-input__icon {
    transform: rotate(180deg);
}

.zd-cascader-wrap.is-focus .zd-cascader__dropdown {
    display: inline-block;
}

.zd-cascader-wrap:focus-within .zd-input__inner {
    border-color: #409eff;
}

.is-selected-icon {
    position: absolute;
    left: 10px;
    font-size: 13px;
}